<template>
  <div>
    <validation-observer ref="form">
      <b-form>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h3>Content</h3>
            </b-col>
            <b-col sm="12">
              <b-tabs pills>
                <b-tab title="English" active>
                  <b-form-group>
                    <label for="content-whoweare-title">Title <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="content.english.title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <label for="content-whoweare-description">Description</label>
                    <b-form-textarea
                      v-model="content.english.description"
                      rows="5"
                      placeholder="Description"
                    />
                  </b-form-group>
                </b-tab>
                <b-tab title="Thai">
                  <b-form-group>
                    <label for="content-whoweare-title">Title</label>
                    <b-form-input
                      v-model="content.thai.title"
                      placeholder="Title"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="content-whoweare-description">Description</label>
                    <b-form-textarea
                      v-model="content.thai.description"
                      rows="5"
                      placeholder="Description"
                    />
                  </b-form-group>
                </b-tab>
                <b-tab title="Chinese">
                  <b-form-group>
                    <label for="content-whoweare-title">Title</label>
                    <b-form-input
                      v-model="content.chinese.title"
                      placeholder="Title"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="content-whoweare-description">Description</label>
                    <b-form-textarea
                      v-model="content.chinese.description"
                      rows="5"
                      placeholder="Description"
                    />
                  </b-form-group>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h3>Status</h3>
            </b-col>
            <b-col sm="12">
              <b-form-group>
                <label for="status">Status <span class="text-red">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <v-select
                    v-model="status"
                    :state="errors.length > 0 ? false : null"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="status"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

    <div class="fixed-footer justify-content-end">
      <b-button
        variant="outline-secondary"
        :to="{ name: 'about-whoweare'}"
      >
        <span>Cancel</span>
      </b-button>
      <b-button
        class="ml-1"
        variant="success"
        type="submit"
        @click.prevent="validationForm"
      >
        <span>Save</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormRadioGroup, BFormRadio, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BTab, BTabs, BFormTextarea } from 'bootstrap-vue'
import { required, email, regex } from '@validations'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useWhoWeAreCreate from './useWhoWeAreCreate'
import whoWeAreStoreModule from '../whoWeAreStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormRadioGroup,
    BFormRadio,
    BButton,
    BForm,
    BImg,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTab,
    BTabs,

    vSelect,
  },
  data() {
    return {
      required,
      regex,
      email,
      status: 'Activate',
      content: {
        thai: {
          title: '',
          description: '',
        },
        english: {
          title: '',
          description: '',
        },
        chinese: {
          title: '',
          description: '',
        },
      },
      avoidRouteLeave: false,
    }
  },
  methods: {
    async validationForm() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) return

      const { status } = await this.addCard({
        status: this.status,
        thai: this.content.thai,
        english: this.content.english,
        chinese: this.content.chinese,
      })
      if (status !== 200) {
        this.makeToast(false)
        return;
      }

      this.avoidRouteLeave = true
      this.$router
        .push({ name: 'about-whoweare' })
        .then(() => {
          this.makeToast(true)
        })
    },
    makeToast(success) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{success ? 'Create succeeded!' : 'Create failed!' }</h4>
              <div class="ml-1 font-14px">{success ? `You has been created “${this.content.english.title}” card.` : 'Can not create card.'}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  beforeRouteLeave(_, from, next) {
    if (this.avoidRouteLeave) {
      next()
      return
    }

    this.$swal({
      title: "Are you sure to leave?",
      text: "Do you really want to leave? you have unsaved changes!",
      icon: "warning",
      iconColor: "#FF9F43",
      showCancelButton: true,
      confirmButtonText: "Leave",
      customClass: {
        htmlContainer: "my-50",
        actions: "flex-row-reverse my-1",
        confirmButton: "btn btn-danger px-5",
        cancelButton: "btn btn-outline-secondary px-5 mr-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (!result.value) {
        next(false);
      } else {
        next()
      }
    });
  },
  setup() {
    const WHOWEARE_APP_STORE_MODULE_NAME = 'app-whoweare'

    // Register module
    if (!store.hasModule(WHOWEARE_APP_STORE_MODULE_NAME)) store.registerModule(WHOWEARE_APP_STORE_MODULE_NAME, whoWeAreStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WHOWEARE_APP_STORE_MODULE_NAME)) store.unregisterModule(WHOWEARE_APP_STORE_MODULE_NAME)
    })

    const {
      toast,
      addCard,
      statusOptions,
    } = useWhoWeAreCreate()

    return {
      toast,
      addCard,
      statusOptions,
    }
  },
}
</script>