import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useWhoWeAreCreate() {
  const toast = useToast()
  const statusOptions = [
    { label: 'Activate', value: 'Activate' },
    { label: 'Inactivate', value: 'Inactivate' },
  ]
  
  const addCard = async (card) => {
    try {
      const response = await store.dispatch('app-whoweare/addCard', card);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error update setting',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    toast,
    statusOptions,
    addCard,
  }
}
